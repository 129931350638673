import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Login from "./Login.tsx";  // Import the Login component
import Home from "./Home.tsx";    // Import the Home component
import { auth } from "./firebase.ts"; // Ensure your firebase config is correct

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check if user is logged in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div>
        <img src="https://i.imgur.com/B6w7DwY.png" alt="Circal Logo" className="logo" />
        <Routes>
          {/* Default route for Login */}
          <Route path="/" element={<Login />} />

          {/* Protected Home Route */}
          <Route
            path="/home"
            element={
              isAuthenticated ? <Home /> : <Navigate to="/" replace />
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

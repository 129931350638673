import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"; // Import your Firebase auth configuration

interface Event {
  title: string;
  start: string;
  end: string;
  description: string;
  location: string;
  status: string;
  isPrivate: boolean;
  circleId: string | null;
}

const Home: React.FC = () => {
  // Form state for creating an event
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("2024-12-01T10:00:00Z");
  const [end, setEnd] = useState("2024-12-01T11:00:00Z");
  const [isPrivate, setIsPrivate] = useState(false);
  const [circleId, setCircleId] = useState<string | null>(null);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("busy");

  const [authToken, setAuthToken] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [events, setEvents] = useState<Event[]>([]); // State for storing fetched events

  // Fetch the auth token when the component mounts
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fetch user's events from the API
  useEffect(() => {
    const fetchEvents = async () => {
      if (!authToken) return;

      try {
        const response = await fetch("https://events.circal.fun/events", {
          headers: { Authorization: `Bearer ${authToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          setEvents(data); // Set events data from API response
        } else {
          console.error("Failed to fetch events:", response.status);
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [authToken]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!authToken) {
      console.error("Authentication token is missing");
      return;
    }

    const event = {
      title,
      start,
      end,
      isPrivate,
      circleId,
      description,
      location,
      status,
    };

    try {
      const response = await fetch("https://events.circal.fun/events", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event),
      });

      if (response.ok) {
        console.log("Event created successfully");
        setShowSuccess(true);
        setTimeout(() => setShowSuccess(false), 3000);

        // Clear the form fields
        setTitle("");
        setIsPrivate(false);
        setCircleId(null);
        setDescription("");
        setLocation("");
        setStatus("busy");

        // Refresh events list after creating a new event
        const updatedEvents = await fetch("https://events.circal.fun/events", {
          headers: { Authorization: `Bearer ${authToken}` },
        });
        if (updatedEvents.ok) setEvents(await updatedEvents.json());
      } else {
        console.error("Failed to create event:", response.status);
      }
    } catch (error) {
      console.error("Error creating event:", error);
    }
  };

  const handleGoogleSync = async () => {
    if (!authToken) {
      console.error("Authentication token is missing");
      return;
    }

    // Construct the API URL with query parameters
    const apiUrl = `https://events.circal.fun/google/auth?authToken=${authToken}`;

    try {
      // Redirect the user to the API endpoint
      window.location.href = apiUrl;
    } catch (error) {
      console.error("Error initiating Google Calendar sync:", error);
    }
  };

  return (
    <div className="home-container">
      <div className="create-event-pane">
        <h2>Create an Event</h2>
        <form onSubmit={handleSubmit} className="event-form">
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Enter event title"
            />
          </div>
          <div className="form-group">
            <label>Start Date and Time</label>
            <input
              type="datetime-local"
              value={start}
              onChange={(e) => setStart(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>End Date and Time</label>
            <input
              type="datetime-local"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>Privacy</label>
            <div className="checkbox-group">
              <input
                type="checkbox"
                checked={isPrivate}
                onChange={(e) => setIsPrivate(e.target.checked)}
                id="isPrivate"
              />
              <label htmlFor="isPrivate">Private Event</label>
            </div>
          </div>
          <div className="form-group">
            <label>Circle ID</label>
            <input
              type="text"
              value={circleId || ""}
              onChange={(e) => setCircleId(e.target.value || null)}
              placeholder="Enter circle ID (optional)"
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter event description"
              required
            />
          </div>
          <div className="form-group">
            <label>Location</label>
            <input
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              required
              placeholder="Enter event location"
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="busy">Busy</option>
              <option value="free">Free</option>
            </select>
          </div>
          <button type="submit" className="btn create-event-btn">
            + Create Event
          </button>
        </form>

        {/* Success message */}
        {showSuccess && (
          <div className="success-message">Event successfully created!</div>
        )}

        {/* Google Calendar Sync Button */}
        <div className="google-sync-container">
          <button onClick={handleGoogleSync} className="btn google-sync-btn">
            Sync with Google Calendar
          </button>
        </div>
      </div>

      {/* My Events Section */}
      <div className="my-events-pane">
        <h2>My Events</h2>
        <div className="events-list">
          {events.map((event, index) => (
            <div key={index} className="event-card">
              <h3>{event.title}</h3>
              <p><strong>Start:</strong> {new Date(event.start).toLocaleString()}</p>
              <p><strong>End:</strong> {new Date(event.end).toLocaleString()}</p>

              {/* Conditionally render location, status, and description */}
              {event.location && <p><strong>Location:</strong> {event.location}</p>}
              {event.status && <p><strong>Status:</strong> {event.status}</p>}
              {event.description && <p><strong>Description:</strong> {event.description}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;

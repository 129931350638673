import React, { useState } from "react";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "./firebase.ts"; // Ensure your firebase config is correct
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const [isRegistering, setIsRegistering] = useState(false); // Toggle between login and register
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>(""); // For registration
  const [name, setName] = useState<string>(""); // For registration
  const [dob, setDob] = useState<string>(""); // Date of birth for registration
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null); // For success messages
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false); // Toggles password reset
  const navigate = useNavigate();


  const handlePasswordReset = async () => {
    setError(null);
    setMessage(null);
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("A password reset link has been sent to your email.");
    } catch (err) {
      setError("Failed to send password reset email. Please try again.");
    }
  };

  // Function to check if user is 16 or older
  const isUserSixteen = (dob: string): boolean => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age >= 16;
  };

  // Login handler
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("Login successful"); // Log success to confirm authentication
      navigate("/home"); // Redirect to /home after successful login
    } catch (err) {
      console.error("Error during login:", err); // Log the error details
      setError("Failed to login. Please check your credentials."); // Display error to the user
    }
  };


  // Registration handler with Firebase
  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Validate passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    // Check if the user is at least 16 years old
    if (!isUserSixteen(dob)) {
      setError("You must be at least 16 years old to register.");
      return;
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Reset the form, and switch back to the login form
      setPassword("");  // Clear the password
      setConfirmPassword("");  // Clear the confirm password
      setDob("");  // Clear the date of birth
      setName("");  // Clear the name field

      // Show the login form and keep the email prefilled
      setIsRegistering(false);
      setError(null);  // Clear any error messages

      alert("Registration successful! You can now log in.");
      
    } catch (error: any) {
      console.error("Registration error:", error);

      // Handle Firebase errors
      if (error.code === "auth/email-already-in-use") {
        setError("This email is already in use.");
      } else if (error.code === "auth/invalid-email") {
        setError("Invalid email address.");
      } else if (error.code === "auth/weak-password") {
        setError("Password should be at least 6 characters long.");
      } else {
        setError("Failed to register. Please try again.");
      }
    }
  };

  // Google Sign-In handler
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      alert("Google Sign-In successful");
      navigate("/home");
      // Redirect or update UI upon successful login
    } catch (err) {
      //setError("Failed to sign in with Google.");
    }
  };

return (
    <div className="login-container">
      {error && <p style={{ color: "red" }}>{error}</p>}
      {message && <p style={{ color: "green" }}>{message}</p>}

      {showResetPassword ? (
        <div className="reset-password-container">
  <h2>Reset Password</h2>
  <input
    type="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
    className="input-box"
    placeholder="Enter your email"
  />
  <div className="reset-password-buttons">
    <button onClick={handlePasswordReset} className="btn">
      Send Reset Link
    </button>
    <button
      onClick={() => setShowResetPassword(false)}
      className="btn back-to-login"
    >
      Back to Login
    </button>
  </div>
</div>
      ) : (
        <form onSubmit={isRegistering ? handleRegister : handleLogin}>
          {isRegistering && (
            <div>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="input-box"
                placeholder="Name"
              />
            </div>
          )}
          <div>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-box"
              placeholder="Email"
            />
          </div>
          <div>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="input-box"
              placeholder="Password"
            />
          </div>
          {isRegistering && (
            <>
              <div>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="input-box"
                  placeholder="Confirm Password"
                />
              </div>
              <div>
                <input
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  required
                  className="input-box"
                  placeholder="Date of Birth"
                />
              </div>
            </>
          )}
          {!isRegistering && (
            <div>
              <p
                className="forgotPasswordLink"
                onClick={() => setShowResetPassword(true)}
                style={{ cursor: "pointer", color: "blue" }}
              >
                Forgot Password?
              </p>
            </div>
          )}
          <div className="container">
            <div className="left-side">
              <button type="submit" className="btn sign-in">
                {isRegistering ? "Register" : "Sign in"}
              </button>
            </div>
            <div className="right-side">
              <button
                type="button"
                className="btn register"
                onClick={() => {
                  setIsRegistering(!isRegistering);
                  setError(null); // Clear any error messages when switching forms
                }}
              >
                {isRegistering ? "Back to Login" : "Register"}
              </button>
            </div>
          </div>
          {!isRegistering && (
            <>
              <div className="or-divider">
                <span></span>
                <p>OR</p>
                <span></span>
              </div>
              <div className="or-divider">
                <button type="button" className="googleSignIn" onClick={handleGoogleSignIn}>
                  Sign in with Google
                </button>
              </div>
            </>
          )}
        </form>
      )}
    </div>
  );
};

export default Login;
